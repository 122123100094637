
import React from "react";
import "./drop_logo.css";
//import * as AWS from 'aws-sdk'
//import {fetchData, putData} from './dbServices.js';
import TagManager from 'react-gtm-module';
import { createTodo } from '../graphql/mutations';
//import { listTodos } from './graphql/queries';
import { API, graphqlOperation } from "aws-amplify";
//import awsExports from "./aws-exports";

const addToDo = async () => {
    try {
        await API.graphql(graphqlOperation(createTodo, {
            input: {
                id: "23456",
                name: "first to do",
                description: "discript it all"
            }
        }))
    } catch (err) {
        console.log("not ok: ", err)
    }
}


/*
if (!AWS.config.region) {
    AWS.config.update({
      region: 'eu-west-3'
    });
}




const fetchDataFormDynamoDb = async () => {
    return fetchData('beer_api_v2')
}
const addDataToDynamoDB = async () => {
    const beerData = {
        IdentityId:"123456",
        id:"123456"
    }
    
    await putData('beer_api_v2' , beerData)
}
*/

let beerData = require('./all_beers.json');

var colorBuilder = (number) => {
    if (number > 7) {
        number = number - 8
    }
    const bgColor = ["#794039", "#5d635e", "#b5986c","#6e6e6e","#794039", "#5d635e", "#b5986c","#6e6e6e"];
    const hColor = ["#6e6e6e","#794039", "#5d635e", "#b5986c","#6e6e6e","#794039", "#5d635e", "#b5986c"];
    let colorSchema = {
        bg: bgColor[number],
        hc: hColor[number]
    }
    return colorSchema
}

const BeerCard = (props) => {
    
    let testData = props.beerData
    let beers = testData;
    let batches = beers[0].data.batches
    let completedBeers = getCompleted(batches)
    const beerCard = (
    <div>
        <header>
            <div className="carousel-inner" role="listbox">
                <div className="drop-container">
                    <div className="drop">
                        <img className="img-brew" alt="mudde_brewery_logo "src="img/Logo Mudde Brewery_pink_v2.png"></img>
                    </div>
                </div>
            </div>
            {/*<span className="scroll-btn">
                <a href="#">
                    <span className="mouse">
                        <span className="scroll-btn scroll-span"></span>
                    </span>
                </a>
                <p className="scroll-btn scroll-text">scroll me</p>
            </span>*/}
        </header>
        {/* <div className="cont-wide" id="react-fill">
            <div className="row-right">
                {completedBeers.map((beer, i) => 
                    <div key={beer.id} className="col-lg-4" data-key={i + 1}>
                        <div className="brewcardmain wide" style={{backgroundColor:colorBuilder(i).bg}}>
                            <h1 className="brewcardheader" style={{color:colorBuilder(i).hc}}>{beer.name}</h1>
                            <div className="brewcard-body">
                                <p className="card-text brewcard-text" style={{color:colorBuilder(i).hc}}>{beer.description}</p>
                            </div>
                            <div className="brewcard-dash">
                                <div className="brewcard-infobox dashcard">
                                    <div className="dash" style={{borderColor: colorBuilder(i).hc}}></div>
                                </div>
                                <div className="brewcard-infobox dashcard">
                                    <div className="dash" style={{borderColor: colorBuilder(i).hc}}></div>
                                </div>                        
                                <div className="brewcard-infobox dashcard">
                                    <div className="dash" style={{borderColor: colorBuilder(i).hc}}></div>
                                </div>
                            </div>
                            <div className="brewcard-bottom">
                                <div className="brewcard-infobox">
                                    <h3 className="info-text" style={{color:colorBuilder(i).hc}}>abv</h3>
                                    <h4 className="info-data" style={{color:colorBuilder(i).hc}}>{beer.abv}%</h4>
                                </div>
                                <div className="brewcard-infobox">
                                    <h3 className="info-text" style={{color:colorBuilder(i).hc}}>ibu</h3>
                                    <h4 className="info-data" style={{color:colorBuilder(i).hc}}>{beer.ibu}</h4>
                                </div>
                                <div className="brewcard-infobox">
                                    <h3 className="info-text" style={{color:colorBuilder(i).hc}}>srm</h3>
                                    <h4 className="info-data" style={{color:colorBuilder(i).hc}}>{beer.color}</h4>    
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                </div>
            <div className="row-right">
            </div>
        </div>
                <div id="like_button_container"></div> 
        <button onClick={() => fetchDataFormDynamoDb()}> Fetch </button>*/}
                <button onClick={() => addToDo()}> Fetch </button>
        
    </div>
    )
    return (beerCard)
}

//GET DAYS PASSED
function daysPassed(inputDate) {
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((inputDate - Date.now()) / oneDay));
    return diffDays
}

//TIME CONVERTER
function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = new Date(UNIX_timestamp).getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = date + ' ' + month + ' ' + year;
    return time;
}
// GET COMPLETED BEERS
function getCompleted(input) {
    let completed = []
    for (var i = 0; i < input.length; i++) {
        if (input[i].status === "Completed") {
            let usedHops = []
            input[i].batchHops.forEach(element => {
                usedHops.push(element.name)
            });
            completed.push({
                "id": input[i]._id,
                "ibu": input[i].estimatedIbu,
                "abv": input[i].measuredAbv,
                "name": input[i].recipe.name,
                "color": input[i].recipe.color,
                "hops": usedHops.join(", "),
                "bottlingDate": timeConverter(input[i].bottlingDate),
                "days_old": daysPassed(input[i].bottlingDate),
                "description": input[i].recipe.teaser
            })
        }
    }
    return completed
}

const tagManagerArgs = {
    dataLayer: {
        "event": "test",
        "page": "home"
    }
}

const Home = () => {
    TagManager.dataLayer(tagManagerArgs)

    return (<BeerCard beerData={beerData}/>)
}

export default Home